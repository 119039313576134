import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useSupplantToken from "./useSupplantToken";

export default function useToken() {
    const { token: storedToken } = useSelector((state: any) => state)
    const { supplantToken } = useSupplantToken()
    const [token, setToken] = useState<string | undefined>(supplantToken || storedToken);

    useEffect(() => {
        if (!supplantToken && storedToken) {
            setToken(storedToken);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
        
    return [token, setToken];
}