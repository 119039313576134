import React, { useContext, useEffect, useRef, useState } from 'react'

import {
    Grid,
    Icon,
    Header,
    Button,
    Form,
    Progress,
    Select,
    Popup
} from 'semantic-ui-react'

import './UserList.scss'

import { useDispatch, useSelector } from 'react-redux';
import { useLazyQuery, useMutation } from '@apollo/client'
import LoaderContext from '../../Library/Context/LoaderContext'
import useMobile from '../../Hooks/useMobile'
import TopBar from '../../Components/TopBar'

import { ADMIN_CREATE_PILL } from './connections'
import { AdminMenu } from './AdminMenu'
import axiosInstance from '../../Library/axiosInstance';

import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { useHistory, useParams } from 'react-router-dom';
import { GET_COURSE_DETAIL } from '../connections';
import { logout } from '../../Store/Actions';

const baseURL = process.env.NODE_ENV === 'production' ? 'https://api.dcvevolucion.cl/' : 'http://localhost:4000/'

type StepTypeEnum = 'text' | 'time' | 'tooltip'

export default function PillCreate() {
    const { isMobile } = useMobile()
    const { token, learningRoutes } = useSelector((state: any) => state)
    const { resourceId, learningRouteId } = useParams<any>()

    const [editor, setEditor] = useState(EditorState.createEmpty())

    const [selectedFiles, setSelectedFiles] = useState([])
    const [progress, setProgress] = useState<number>(0)
    const dispatch = useDispatch()
    const history = useHistory()

    const inputFileupload = useRef<any>()

    const [formUser, setFormUser] = useState({
        learningRouteId: 0,
        title: '',
        goal: '',
        url: '',
        timeline: [{
            text: '',
            time: '',
            tooltip: ''
        }],
        relatedPills: [],
        description: {
            internal: '',
            external: ''
        }
    })
    const { setLoading } = useContext(LoaderContext)
    
    const [createPill, { loading, data, error }] = useMutation(ADMIN_CREATE_PILL, {
        context: {
            headers: {
                authorization: `Bearer ${token}`
            }
        }
    })

    const [getData, editData] = useLazyQuery(GET_COURSE_DETAIL, {
        onError: (error) => {
            if (error.message === 'Unauthenticated') {
                dispatch(logout(null))
                return history.push('/', {
                    error
                })
            }
        },
        fetchPolicy: 'no-cache',
        context: {
            headers: {
                authorization: `Bearer ${token}`
            }
        }
    })

    useEffect(() => {
        if(resourceId) {
            const localId = Number(resourceId)
            getData({
                variables: {
                    CourseDetailInput: {
                        courseId: localId,
                        learningRouteId: Number(learningRouteId)
                    }
                }
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resourceId])

    useEffect(() => {
        if(editData?.data?.getCourseDetail) {
            const { Course, LearningResources: [Resource] } = editData?.data?.getCourseDetail

            const { name, description, learningRouteId } = Course
            const { configuration: rawConfig, description: goal, extendedDescription: rawDescription, timeline: rawTimeline } = Resource
            const config = JSON.parse(rawConfig)
            
            console.log({
                description,
                rawDescription
            })

            setFormUser({
                learningRouteId,
                title: name,
                goal,
                url: config.url,
                timeline: JSON.parse(rawTimeline),
                relatedPills: [],
                description: {
                    internal: rawDescription,
                    external: description
                }
            })

            const contentBlock = htmlToDraft(rawDescription)
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            setEditor(editorState)
            setProgress(100)
        }
    }, [editData.data])
    
    useEffect(() => {
        setLoading(loading || editData.loading)
    }, [loading, setLoading, editData.loading])

    useEffect(() => {
        if (!isMobile && !loading && data?.UserLearningRoutes?.LearningRoute) {
            let menuWidth = [].slice.call(document.getElementsByClassName('MenuItemElement')).reduce((acc, lr: any) => {
                /* console.log({
                    word: lr.innerHTML,
                    length: lr.innerHTML.length + 0,
                    width: lr.offsetWidth,
                    letterWidth: lr.offsetWidth / lr.innerHTML.length
                }) */
                return acc + lr.offsetWidth
            }, 0)
            if (menuWidth > window.innerWidth) {
                // ¿Cuantos caben?
                // Promedio por letra: 12px.
            }
            /* for (const elementt of ) {
                menuWidth += elementt.offsetWidth
            } */
        }
    }, [isMobile, data, loading])

    useEffect(() => {
        if (data?.adminCreatePill?.success) {
            // @ts-ignore
            window.location = '/'
        }
    }, [data])

    const onEditorStateChange = (editorState: any) => {
        setEditor(editorState)
    };

    useEffect(() => {
        const html = draftToHtml(convertToRaw(editor.getCurrentContent()))
        const update = {...formUser}
        // @ts-ignore
        update.description.internal = html
        setFormUser(update)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editor])

    /**
     * 
     * @example 
     * updateForm(null, { name: 'key', value: 'value' })
     * updateForm(null, { name: data.id, value: data.value })
     * updateForm(null, { name: data.name, value: data.checked })
     */
    const updateForm = (e: any, data: any) => {
        const { name, id, value, checked } = data
        const identifier = !name ? id : name
        const realValue = !value ? checked : value
        
        const update = {...formUser}
        // @ts-ignore
        update[identifier] = realValue
        setFormUser(update)
    }

    const submitForm = () => {
        const variables = {
            AdminCreatePillInput: {
                ...formUser,
                id: 0
            }
        }
        if (resourceId) {
            // Editando
            variables.AdminCreatePillInput.id = Number(resourceId)
        }
        window.scrollTo(0, 0)
        createPill({
            variables
        })
    }

    const submitHandler = async (e: any) => {
        setLoading(true)
        if (e) {
            e.preventDefault() //prevent the form from submitting
        }
        let formData = new FormData()
        formData.append("file", selectedFiles[0])
        const res = await axiosInstance.post("/upload_media", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": `Bearer ${token}`
            },
            onUploadProgress: data => {
                console.log({ data })
                //Set the progress value to show the progress bar
                setProgress(Math.round((100 * data.loaded) / data.total))
            }
        })
        if (res) {
            setLoading(false)
            updateForm(null, { name: 'url', value: res.data.url })
        }
    }

    useEffect(() => {
        if (error) {
            alert(error)
        }
    }, [error])

    useEffect(() => {
        if (selectedFiles && selectedFiles.length > 0) {
            submitHandler(null)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFiles])

    return <Grid className="ViewCourses">
        <TopBar />
        <Grid.Row columns="equal">
            <AdminMenu />
            
            <Grid.Column>
                <Grid.Row>
                    <Header as='h2' style={{
                        marginBottom: 16
                    }}>
                        <Icon name='settings' />
                        <Header.Content>
                        Creación de contenido
                        <Header.Subheader>Registra nuevo contenido en tu plataforma de estudios.</Header.Subheader>
                        </Header.Content>
                    </Header>
                </Grid.Row>
                <Grid.Row>
                    <form
                        action={`${baseURL}upload_media`}
                        method="post"
                        encType="multipart/form-data"
                    >
                        <Form.Group>
                            <input
                                id="exampleFormControlFile1"
                                type="file"
                                name="file"
                                style={{
                                    display: 'none'
                                }}
                                ref={inputFileupload}
                                onChange={e => {
                                    // @ts-ignore
                                    setSelectedFiles(e.target.files)
                                }}
                            />
                            <Button content='Seleccionar video para cargar...' icon='upload' as='a' labelPosition='left' onClick={() => {
                                inputFileupload.current.click()
                            }} />
                        </Form.Group>
                        <Progress percent={progress} autoSuccess label={`${progress}%`} style={{ marginTop: 16 }} />
                    </form>
                    
                    {formUser.url !== '' && <Form onSubmit={(event, data) => {
                        submitForm()
                    }}>
                        {/* learningRouteId: 0,
                        title: '',
                        goal: '',
                        url: '',
                        timeline: [{
                            text: '',
                            time: '',
                            tooltip: ''
                        }],
                        relatedPills: [],
                        description: {
                            internal: '',
                            external: ''
                        } */}
                        <Form.Input id='title' value={formUser.title} label='Título de la Cápsula' placeholder='ejemplo: Instalación de RSA' onChange={updateForm} />
                        <Form.Input id='goal' value={formUser.goal} label='Objetivo' placeholder='ejemplo: Instalar correctamente el dispositivo RSA' onChange={updateForm} />
                        <Form.Field label='Selecciona a que ruta de aprendizaje pertenece:' />
                        <Select value={formUser.learningRouteId} fluid placeholder='Selecciona una ruta de aprendizaje' options={learningRoutes.map((route: any) => {
                            return {
                                key: route.id,
                                value: route.id,
                                text: route.name
                            }
                        })} onChange={(e, { value }) => {
                            const update = {...formUser}
                            // @ts-ignore
                            update.learningRouteId = value
                            setFormUser(update)
                        }} />
                        <br />
                        <Form.TextArea value={formUser.description.external} id='descriptionExternal' label='Descripción' placeholder='ejemplo: En esta cápsula aprenderás a instalar correctamente el dispositivo RSA' onChange={(e: any, data: any) => {
                            const { value, checked } = data
                            const realValue = !value ? checked : value
                            
                            const update = {...formUser}
                            // @ts-ignore
                            update.description.external = realValue
                            setFormUser(update)
                        }} />
                        <Form.Field label='Para tener en cuenta:' />
                        <Editor
                            editorState={editor}
                            onEditorStateChange={onEditorStateChange}
                            toolbar={{
                                options: ['inline', 'list']
                            }}
                            />
                        <br />
                        <Form.Field label='Paso a Paso:' />

                        {formUser.timeline && <>
                            {formUser.timeline.map((item, ndx) =>
                                <StepItem item={item} ndx={ndx} update={(data: any, keyIndex: StepTypeEnum) => {
                                    // (e: any, data: any) => {
                                    const { value, checked } = data
                                    const realValue = !value ? checked : value
                                    
                                    const update = {...formUser}

                                    const uptaX = {...update.timeline[ndx]}
                                    // @ts-ignore
                                    uptaX[keyIndex] = realValue

                                    update.timeline[ndx] = uptaX
                                    setFormUser(update)
                                    // }
                                }} />
                            )}
                            <Button basic color='green' content='Añadir nuevo paso' icon='add' as='a' labelPosition='left' onClick={() => {
                                const update = {...formUser}
                                // @ts-ignore
                                update.timeline.push({
                                    text: '',
                                    // @ts-ignore
                                    time: null,
                                    tooltip: ''
                                })
                                setFormUser(update)
                            }} />
                            <Button basic color='red' content='Quitar último paso' icon='add' as='a' labelPosition='left' onClick={() => {
                                if (formUser.timeline.length > 1) {
                                    const update = {...formUser}
                                    // @ts-ignore
                                    update.timeline.pop()
                                    setFormUser(update)
                                }
                            }} />
                        </>}
                        <br /><br />
                        <Button positive>Guardar</Button>
                        {resourceId && <Button onClick={(e) => {
                            e.preventDefault()
                            window.open(`/course/view/${formUser.learningRouteId}-preview/${resourceId}-preview`, "_blank")
                        }}>Previsualizar</Button>}
                    </Form>}
                </Grid.Row>
            </Grid.Column>
        </Grid.Row>
    </Grid>
}

const StepItem = ({ item, ndx, update }: any) => {
    const [checkbox, setCheckbox] = useState<boolean>(!!item.tooltip)
    const [editor, setEditor] = useState(EditorState.createEmpty())

    useEffect(() => {
        if (!checkbox) {
            update({ value: '', checked: ''}, 'tooltip')
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkbox])

    useEffect(() => {
        if (item?.tooltip?.length > 0) {
            const contentBlock = htmlToDraft(item.tooltip)
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            setEditor(editorState)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onEditorStateChange = (editorState: any) => {
        // Actualizar estado local
        setEditor(editorState)
        // Actualizar estado global HTML
        const html = draftToHtml(convertToRaw(editor.getCurrentContent()))
        update({ value: html }, 'tooltip')
    };

    return <div className="StepItemContainer">
        <div className="StepItem" key={`TimelineItem${ndx}`}>
            <Form.Input
                id={`TimelineItem${ndx}`}
                value={item.text}
                className={`fieldInputLine`}
                placeholder={`Paso número ${ndx + 1}`}
                onChange={(e, data) => update(data, 'text')}/>
            <Popup inverted content={`${!checkbox ? 'Activa' : 'Desactiva'} descripción HTML`} trigger={<Form.Checkbox
                id={`TimelineCheckboxItem${ndx}`}
                label={{ children: <Icon name='html5' /> }}
                checked={checkbox}
                onChange={(e, data) => { setCheckbox(data.checked!) }}/>} />
        </div>
        {checkbox && <div className="StepItemEditor">
            <Editor
                editorState={editor}
                onEditorStateChange={onEditorStateChange}
                toolbar={{
                    options: ['inline', 'list'],
                    inline: {
                        options: ['bold', 'italic', 'underline']
                    }
                }}
                />
        </div>}
    </div>
}