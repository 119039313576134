import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import useToken from './useToken'

export default function useSupplant () {
    const [supplant, setSupplant] = useState<boolean>(false)
    const { token: storedToken } = useSelector((state: any) => state)
    const [token] = useToken()

    useEffect(() => {
        setSupplant(token !== storedToken)
    }, [token, storedToken])

    return {
        supplant
    }
}