import React, { useContext, useEffect, useState } from 'react'

import {
    Grid,
    Icon,
    Header,
    Button,
    Form,
    Checkbox
} from 'semantic-ui-react'

import './UserList.scss'

import { useSelector } from 'react-redux';
import { useLazyQuery, useMutation } from '@apollo/client'
import LoaderContext from '../../Library/Context/LoaderContext'
import useMobile from '../../Hooks/useMobile'
import TopBar from '../../Components/TopBar'

import { ADMIN_CREATE_USER, ADMIN_GET_USER_LIST } from './connections'
import { AdminMenu } from './AdminMenu'
import { useParams } from 'react-router-dom';

export default function UserCreate() {
    const { isMobile } = useMobile()
    const { token } = useSelector((state: any) => state)
    const { userId } = useParams<any>()
    const [editMode, ] = useState(Number(userId) > 0) // if userId is greater than 0, then we are editing)

    const [getUserData, epUserData] = useLazyQuery(ADMIN_GET_USER_LIST, {
        // onError: (error) => {
        //     // @todo: Error sin permisos.
        //     if (error.message === 'Unauthenticated') {
        //         dispatch(logout(null))
        //         return history.push('/', {
        //             error
        //         })
        //     }
        // },
        fetchPolicy: 'no-cache',
        context: {
            headers: {
                authorization: `Bearer ${token}`
            }
        },
        variables: {
            AdminUserListInput: {
                search: Number(userId)
            }
        }
    })

    const [formUser, setFormUser] = useState({
        firstName: '',
        lastName: '',
        mail: '',
        canTrainers: false,
        canInterna: false
    })

    const { setLoading } = useContext(LoaderContext)
    
    const [createUser, { loading, data, error }] = useMutation(ADMIN_CREATE_USER, {
        context: {
            headers: {
                authorization: `Bearer ${token}`
            }
        }
    })
    
    useEffect(() => {
        console.log(Number(userId))
        if (editMode) {
            console.log({ editMode, userId })
            getUserData({
                variables: {
                    AdminUserListInput: {
                        search: userId
                    }
                }
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editMode])

    useEffect(() => {
        if (epUserData.data) {
            console.log(epUserData.data.adminGetUserList.users[0].UserGroups.some((group: any) => group.Group.name === 'DCVe Trainers'))
            setFormUser({
                firstName: epUserData.data.adminGetUserList.users[0].firstName,
                lastName: epUserData.data.adminGetUserList.users[0].lastName,
                mail: epUserData.data.adminGetUserList.users[0].mail,
                canTrainers: epUserData.data.adminGetUserList.users[0].UserGroups.some((group: any) => group.Group.name === 'DCVe Trainers'),
                canInterna: epUserData.data.adminGetUserList.users[0].UserGroups.some((group: any) => group.Group.name === 'DCVe Capcitación Interna')
            })
        }
    }, [epUserData.data])

    useEffect(() => {
        setLoading(loading || epUserData.loading)
    }, [loading, setLoading, epUserData.loading])

    useEffect(() => {
        if (!isMobile && !loading && data?.UserLearningRoutes?.LearningRoute) {
            // let menuWidth = [].slice.call(document.getElementsByClassName('MenuItemElement')).reduce((acc, lr: any) => {
            //     return acc + lr.offsetWidth
            // }, 0)
        }
    }, [isMobile, data, loading])

    const updateForm = (e: any, data: any) => {
        const { name, id, value, checked } = data
        const identifier = !name ? id : name
        const realValue = !value ? checked : value
        
        const update = {...formUser}
        // @ts-ignore
        update[identifier] = realValue
        setFormUser(update)
    }

    const submitForm = () => {
        const variables = {
            AdminUserCreateInput: {
                ...formUser
            }
        }

        if (editMode) {
            // @ts-ignore
            variables.AdminUserCreateInput.id = Number(userId)
        }

        createUser({
            variables
        })
    }

    useEffect(() => {
        if (data?.adminCreateUser?.success) {
            if (editMode) {
                alert('El usuario fue modificado con éxito.')
            } else {
                alert(`El usuario fue creado con éxito, la clave es: "${data.adminCreateUser.password}" y fue notificado a "mzuniga@dcv.cl"."`)
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    useEffect(() => {
        if (error) {
            alert(error)
        }
    }, [error])

    return <Grid className="ViewCourses">
        <TopBar />
        <Grid.Row columns="equal">
            <AdminMenu />
            
            <Grid.Column>
                <Grid.Row>
                    <Header as='h2'>
                        <Icon name='settings' />
                        <Header.Content>
                        {editMode ? 'Edición de usuario' : 'Creación de usuario'}
                        <Header.Subheader>
                            {!editMode && <>Registra un nuevo usuario para el acceso a la plataforma, la contraseña será enviada a <strong>mzuniga@dcv.cl</strong> y la podrás ver al finalizar la creación del usuario.</>}
                            {editMode && <>Edición de un perfil de usuario en particular con accesos activos. <br/><br /></>}
                        </Header.Subheader>
                        </Header.Content>
                    </Header>
                </Grid.Row>
                <Grid.Row>
                    <Form onSubmit={(event, data) => {
                        submitForm()
                    }}>
                        <Form.Input id='firstName' label='Nombre(s)' placeholder='Luis Alejandro' value={formUser.firstName} onChange={updateForm} />
                        <Form.Input id='lastName' label='Apellidos(s)' placeholder='Moreno Rubio' value={formUser.lastName} onChange={updateForm} />
                        <Form.Input id='mail' label='Correo electrónico' placeholder='alejandro.rubio@learnlab.cl' value={formUser.mail} onChange={updateForm} />
                        <Form.Field>
                            <Checkbox
                                id='canTrainers'
                                onChange={updateForm}
                                checked={formUser.canTrainers}
                                label='Añadir el participante a Trainers.' />
                        </Form.Field>
                        <Form.Field>
                            <Checkbox
                                id='canInterna'
                                onChange={updateForm}
                                checked={formUser.canInterna}
                                label='Añadir el participante a Capacitación Interna.' />
                        </Form.Field>
                        <Button>Enviar</Button>
                    </Form>
                </Grid.Row>
            </Grid.Column>
        </Grid.Row>
    </Grid>
}