import React, { useContext, useEffect, useState } from 'react'

import { Logo } from '../Assets'

import {
    Grid,
    Dropdown,
    Image,
    Modal,
    Button,
    Form,
    Input,
    Message
} from 'semantic-ui-react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { logout } from '../Store/Actions'

import './TopBar.scss'
import useMobile from '../Hooks/useMobile'
import { useMutation } from '@apollo/client'
import { USER_UPDATE_PROFILE } from './connections'
import LoaderContext from '../Library/Context/LoaderContext'
import useToken from '../Hooks/useToken'
import useSupplant from '../Hooks/useSupplant'
 
export default function TopBar({ setSearching, centerColumn, inverted, style }: any) {
    const { userInfo } = useSelector((state: any) => state)
    const [token] = useToken()

    const dispatch = useDispatch()
    const history = useHistory()
    const { isMobile } = useMobile()
    const [open, setOpen] = useState(false)

    const [changePassword, { data, loading, error }] = useMutation(USER_UPDATE_PROFILE, {
        onError: (error) => {
            if (error.message === 'Unauthenticated') {
                dispatch(logout(null))
                return history.push('/', {
                    error
                })
            }
        },
        fetchPolicy: 'no-cache',
        context: {
            headers: {
                authorization: `Bearer ${token}`
            }
        }
    })

    const { setLoading } = useContext(LoaderContext)

    const [errorForm, setErrorForm] = useState<string>()
    const [enableButton, setEnableButton] = useState<boolean>(false)
    const [form, setForm] = useState<any>()
    const { supplant } = useSupplant()
    
    const { hasRoot } = userInfo

    const updateForm = (e: any, { id, value }: any) => {
        const updated = {...form}
        // @ts-ignore
        updated[id] = value
        setForm(updated)

        // Validations actions
        if (updated?.newPassword && updated?.repeatNewPassword) {
            setErrorForm(updated.newPassword !== updated.repeatNewPassword ? 'La nueva contraseña ingresada no es identica.' : undefined)
        }

        // Enable button
        setEnableButton(updated?.currentPassword?.length > 0 && updated?.newPassword?.length > 0 && updated?.repeatNewPassword === updated?.newPassword)
    }

    const closeModal = () => {
        setOpen(false)
        setForm({})
        setEnableButton(false)
    }

    useEffect(() => {
        setLoading(loading)
    }, [loading, setLoading])
    
    useEffect(() => {
        if (data?.UserUpdateProfile?.success) {
            closeModal()
        }
    }, [data, error])

    return <Grid.Row columns='equal' className={`TopBar ${inverted ? 'TopBar--Inverted' : ''}`} style={style}>
         
        <Grid.Column>
            <Logo className="LogoClicked" onClick={() => {
                if (setSearching) {
                    setSearching(false)
                } else {
                    history.push(`/courses` + history.location.search)
                }
            }} />
        </Grid.Column>
        <Grid.Column width={centerColumn ? 8 : 1}>
            {centerColumn}
        </Grid.Column>
        <Grid.Column>
            {/* @ts-ignore */}
            {true && <Dropdown pointing="top right" text={<div className="DropdownUser--Select" inverted>
                <Image src={supplant ? 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAA1JREFUGFdjMKx78B8ABQICj1vqVAkAAAAASUVORK5CYII=' : userInfo.avatar} avatar className={supplant ? 'supplantAvatar' : undefined} />
                {!isMobile && <div className="Details">
                    <strong>{supplant ? 'Modo Visualización' : userInfo.fullName}</strong><br />
                    <small>{supplant ? <i>La navegación está restringida</i> : userInfo.GroupInfo.name}</small>
                </div>}
            </div>}>
                <Dropdown.Menu>
                    {!supplant && <><Dropdown.Header content='Opciones de Perfil' />
                    <Dropdown.Item icon='mail outline' text='Contactar Pruebas de Mercado' onClick={() => {
                        window.open('mailto:pruebasmercado@dcv.cl')
                    }} />
                    <Modal
                        centered={false}
                        open={open}
                        size='small'
                        onOpen={() => setOpen(true)}
                        onClose={() => setForm({})}
                        trigger={<Dropdown.Item icon='user outline' text='Cambiar contraseña' />}
                        >
                        <Modal.Header>Cambio de contraseña</Modal.Header>
                        <Modal.Content>
                            <Modal.Description>
                                Puedes cambiar tu contraseña para el acceso a esta plataforma de consulta. Te recomendamos por seguridad que hagas un cambio periódico de tus contraseñas.
                            </Modal.Description>
                            {error ? <Message negative>
                                <Message.Header>Ha ocurrido un error al ejecutar el cambio de contraseña</Message.Header>
                                <p>{error.message}</p>
                            </Message> : <br />}
                            <Form>
                                <Form.Field
                                    id='currentPassword'
                                    control={Input}
                                    label='Contraseña actual'
                                    placeholder='Contraseña actual'
                                    onChange={updateForm}
                                    type='password'
                                />
                                <br />
                                <Form.Field
                                    id='newPassword'
                                    control={Input}
                                    label='Nueva contraseña'
                                    placeholder='Nueva contraseña'
                                    onChange={updateForm}
                                    type='password'
                                />
                                <Form.Field
                                    id='repeatNewPassword'
                                    control={Input}
                                    label='Repetir nueva contraseña'
                                    placeholder='Repetir nueva contraseña'
                                    error={errorForm}
                                    onChange={updateForm}
                                    type='password'
                                />
                            </Form>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button disabled={loading} onClick={() => {
                                closeModal()
                            }}>Cancelar</Button>
                            <Button primary loading={loading} disabled={!enableButton} onClick={() => {
                                changePassword({
                                    variables: {
                                        UserUpdateProfileInput: {
                                            currentPasswd: form.currentPassword,
                                            newPasswd: form.newPassword
                                        }
                                    }
                                })
                            }}>Actualizar</Button>
                        </Modal.Actions>
                    </Modal></>}
                    {/*<Dropdown.Item icon='history' text='Mi actividad reciente' />*/}
                    {hasRoot && !supplant && <>
                        <Dropdown.Divider />
                        <Dropdown.Header content='Administración de la Plataforma' />
                        <Dropdown.Item onClick={() => history.push(`/admin/users/list`)} icon='user' text={<>Usuarios<br/><small style={{ marginLeft: 28 }}>Mantenedor de Usuarios</small></>} />
                        {/* <Dropdown.Item icon='users' text={<>Grupos<br/><small style={{ marginLeft: 28 }}>Mantenedor de Grupos</small></>} /> */}
                        <Dropdown.Item onClick={() => history.push(`/admin/courses/list`)}  icon='video play' text={<>Contenidos<br/><small style={{ marginLeft: 28 }}>Mantenedor de Cápsulas</small></>} />
                        <Dropdown.Item onClick={() => history.push(`/admin/report`)}  icon='file' text={<>Informes<br/><small style={{ marginLeft: 28 }}>Descarga y Visualización</small></>} />
                    </>}
                    {!supplant && <Dropdown.Divider />}
                    <Dropdown.Item icon='sign-out alternate' text='Cerrar sesión' description='ctrl + w' onClick={() => {
                        dispatch(logout(null))
                        return history.push('/')
                    }} />
                </Dropdown.Menu>
            </Dropdown>}
            
        </Grid.Column>
    </Grid.Row>
}