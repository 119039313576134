import React, { useContext, useEffect, useState } from 'react'

import {
    Grid,
    Icon,
    Header,
    Step,
    Rating,
    List
} from 'semantic-ui-react'

import './CourseView.scss'

import parse from 'html-react-parser'

import { useHistory, useParams, Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { logout } from '../Store/Actions'
import { useMutation, useQuery } from '@apollo/client'
import { COURSE_QUALIFY, GET_COURSE_DETAIL } from './connections'
import LoaderContext from '../Library/Context/LoaderContext'
import TopBar from '../Components/TopBar'
import useMobile from '../Hooks/useMobile'
import useToken from '../Hooks/useToken'
import useSupplant from '../Hooks/useSupplant'

export default function CourseView() {
    let { learningRouteId, courseId } = useParams<any>()
    learningRouteId = Number(learningRouteId.split('-')[0])
    courseId = Number(courseId.split('-')[0])

    const dispatch = useDispatch()
    const history = useHistory()
    const [token] = useToken()
    const { isMobile } = useMobile()

    const [qtyRate, setQtyRate] = useState<any>(null)
    const [videoURL, setVideoUrl] = useState<any>(null)
    const [timeline, setTimeline] = useState<any>([])
    const { supplant } = useSupplant()
    
    const { setLoading } = useContext(LoaderContext)
    const { loading, data } = useQuery(GET_COURSE_DETAIL, {
        onError: (error) => {
            if (error.message === 'Unauthenticated') {
                dispatch(logout(null))
                return history.push('/', {
                    error
                })
            }
        },
        fetchPolicy: 'no-cache',
        context: {
            headers: {
                authorization: `Bearer ${token}`
            }
        },
        variables: {
            CourseDetailInput: {
                courseId,
                learningRouteId,
                supplant
            }
        }
    })

    const [rate, ratingEndpoint] = useMutation(COURSE_QUALIFY,{
        onError: (error) => {
            if (error.message === 'Unauthenticated') {
                dispatch(logout(null))
                return history.push('/', {
                    error
                })
            }
        },
        fetchPolicy: 'no-cache',
        context: {
            headers: {
                authorization: `Bearer ${token}`
            }
        }
    })

    useEffect(() => {
        if (data?.getCourseDetail) {
            setQtyRate(data?.getCourseDetail?.Course?.qualify)
            setVideoUrl(JSON.parse(data?.getCourseDetail?.LearningResources[0]?.configuration ?? '{}'))
            setTimeline(JSON.parse(data?.getCourseDetail?.LearningResources[0]?.timeline ?? '[]'))
        }
    }, [data])

    useEffect(() => {
        setLoading(loading || ratingEndpoint.loading)
    }, [loading, setLoading, ratingEndpoint.loading])

    if (data?.getCourseDetail?.Course?.typeId === 2) {
        const jsonParams = JSON.parse(data?.getCourseDetail?.LearningResources[0].configuration)
        const { url } = jsonParams
        window.open(url, "_new")
        history.push("/courses")
    }

    return <div className="MainCourseView" style={{
        backgroundColor: '#f8f8f6'
    }}>
        <Grid className="CourseView" style={{
            backgroundColor: '#f8f8f6'
        }}>
            <TopBar style={{
                marginLeft: 28,
                marginRight: 28,
                marginTop: 16,
                marginBottom: 8
            }} />
        </Grid>

        <Grid.Row className="TitleName" style={{
            background: '#cedded4f',
            paddingTop: 16,
            paddingBottom: 16,
            paddingLeft: 28,
            paddingRight: 28
        }}>
            <Header as='h2'>
                <Header.Content>
                {data?.getCourseDetail?.Course.name}
                <Header.Subheader>{data?.getCourseDetail?.LearningResources[0]?.description}</Header.Subheader>
                </Header.Content>
            </Header>
        </Grid.Row>

        <Grid className="CourseView">
            <Grid.Row className="Viewer">
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={isMobile ? 16 : 11}>
                            {videoURL?.url && <video controls controlsList="nodownload" key={videoURL.url}> {/* poster="star.png" */}
                                <source src={videoURL.url} />
                            </video>}
                        </Grid.Column>
                        <Grid.Column width={isMobile ? 16 : 5} className="ObjetiveStep" style={{
                            // backgroundColor: 'rgba(7,23,42,0.10)'
                        }}>
                            <div className="topObjetivesStep">
                                {timeline.length > 0 && <h2>Paso a Paso <small className="totalSteps">({timeline.length} pasos)</small></h2>}
                                <Step.Group vertical fluid size='tiny'>
                                    {timeline.map((item: any, index: number) => {
                                        return <>
                                        <Step active key={`Step${index}`}>
                                            <Icon>{index + 1}</Icon>
                                            <Step.Content>
                                                <Step.Title>{item.text}</Step.Title>
                                                <Step.Description>{item.time}</Step.Description>
                                            </Step.Content>
                                        </Step>
                                        {item.tooltip && <>
                                        <Step active className="subStep" key={`SubStep${index}`}>
                                            <Step.Content>
                                                <Step.Description content={
                                                    <span
                                                    dangerouslySetInnerHTML={{
                                                      __html: item.tooltip
                                                    }}></span>
                                                } />
                                            </Step.Content>
                                        </Step>
                                        </>}
                                    </>})}
                                </Step.Group>
                            </div>

                            <div className="footerVideo">
                                <div className="rateSystem">
                                    <strong>¿Qué tan útil ha sido esta cápsula?</strong>
                                    <Rating
                                        maxRating={5}
                                        icon='star'
                                        size='huge'
                                        disabled={qtyRate !== null || supplant}
                                        onRate={(event, { rating }) => {
                                            rate({
                                                variables: {
                                                    CourseQualifyInput: {
                                                        rating,
                                                        courseId: data?.getCourseDetail?.Course?.id
                                                    }
                                                }
                                            })
                                            setQtyRate(rating)
                                        }}
                                        rating={qtyRate} />                        
                                </div>
                                <div className="relatedCapsules">
                                    <strong>Cápsulas relacionadas</strong><br />
                                    <List>
                                        {data?.getCourseDetail?.RelatedCourses?.length > 0 ? data?.getCourseDetail?.RelatedCourses?.map((link: any) => <List.Item key={`Link${link.name}`}>
                                            <Link to={link.href}>{link.name}</Link>
                                        </List.Item>) : <>
                                            <List.Item><i>No hay capsulas relacionadas.</i></List.Item>
                                        </>}
                                    </List>
                                </div>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Grid.Row>
            {data?.getCourseDetail?.LearningResources[0].extendedDescription.length > 0 && <Grid.Row className="ComplementaryExtra">
                <Grid.Column width={isMobile ? 16 : 16} className="MaterialAdicional">
                    {data?.getCourseDetail?.LearningResources[0] && parse(data?.getCourseDetail?.LearningResources[0]?.extendedDescription)}
                </Grid.Column>
                {/*<Grid.Column width={isMobile ? 16 : 5} className="MaterialAdicional">
                    <Header as='h2'>
                        <Header.Content>
                            Documentos
                        <Header.Subheader>
                            No hay material complementario para este recurso.
                        </Header.Subheader>
                        </Header.Content>
                    </Header>
                </Grid.Column>*/}
            </Grid.Row>}
        </Grid>
    </div>
}