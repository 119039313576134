import React, { useContext, useEffect, useState } from 'react'

import {
    Grid,
    Input,
    Icon,
    Image,
    Header,
    Button,
    Table,
    Popup,
    Pagination,
    Confirm,
    Dropdown
} from 'semantic-ui-react'

import './UserList.scss'

import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { logout } from '../../Store/Actions'
import { useSelector } from 'react-redux';
import { useMutation, useQuery } from '@apollo/client'
import LoaderContext from '../../Library/Context/LoaderContext'
import useMobile from '../../Hooks/useMobile'
import TopBar from '../../Components/TopBar'

import { ADMIN_CREATE_TOKEN, ADMIN_DELETE_ENTITY, ADMIN_GET_USER_LIST } from './connections'
import { RECOVER_PASSWD } from '../connections'
import { AdminMenu } from './AdminMenu'

type UserDataType = {
    id: number
    firstName: string
    lastName: string
    mail: string
    enabled: number
    UserGroups?: any
}

function useQueryParams() {
    const query = new URLSearchParams(useLocation().search)

    return {
        page: Number(query.get('page') || 1),
        search: query.get('search') || undefined
    }
}

export default function UserList() {
    const dispatch = useDispatch()
    const history = useHistory()
    const { isMobile } = useMobile()
    const { token } = useSelector((state: any) => state)
    const { page = 1, search = '' } = useQueryParams();
    
    const [query, setQuery] = useState<string>('')
    const [, setSearching] = useState<boolean>(false)
    const [selectedUser, setSelectedUser] = useState<UserDataType>()
    const [form, setForm] = useState({
        search: '',
        learningRouteId: 0
    })
    const [confirm, setConfirm] = useState<boolean>(false)
    const [confirmDelete, setConfirmDelete] = useState<boolean>(false)
    
    const { setLoading } = useContext(LoaderContext)
    const { loading, data, refetch } = useQuery(ADMIN_GET_USER_LIST, {
        onError: (error) => {
            // @todo: Error sin permisos.
            if (error.message === 'Unauthenticated') {
                dispatch(logout(null))
                return history.push('/', {
                    error
                })
            }
        },
        fetchPolicy: 'no-cache',
        context: {
            headers: {
                authorization: `Bearer ${token}`
            }
        },
        variables: {
            AdminUserListInput: {
                page,
                items: 20,
                search
            }
        }
    })
    const [doRecover, recoverPasswdEvents] = useMutation(RECOVER_PASSWD, {
        context: {
            headers: {
                authorization: `Bearer ${token}`
            }
        },
        fetchPolicy: 'no-cache',
    })

    const [createToken, tokenCreationEvents] = useMutation(ADMIN_CREATE_TOKEN, {
        context: {
            headers: {
                authorization: `Bearer ${token}`
            }
        }
    })

    const [deleteEntity, deleteEntityEvents] = useMutation(ADMIN_DELETE_ENTITY, {
        context: {
            headers: {
                authorization: `Bearer ${token}`
            }
        },
        fetchPolicy: 'no-cache',
    })

    useEffect(() => {
        refetch()
    }, [page, search, refetch])

    useEffect(() => {
        setLoading(loading || recoverPasswdEvents.loading || tokenCreationEvents.loading || deleteEntityEvents.loading)
    }, [loading, recoverPasswdEvents.loading, setLoading, tokenCreationEvents.loading, deleteEntityEvents.loading])

    useEffect(() => {
        if(recoverPasswdEvents.data?.UserRecoverPasswd?.success) {
            alert(`Contraseña reiniciada con éxito para ${selectedUser?.mail} y ha sido enviado el correo a "mzuniga@dcv.cl".`)
            setSelectedUser(undefined)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recoverPasswdEvents.data])

    useEffect(() => {
        if (deleteEntityEvents.data?.adminDeleteEntity?.success) {
            alert(`Usuario ${selectedUser?.mail} eliminado con éxito.`)
            setSelectedUser(undefined)
            history.push('/admin/users/list')
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteEntityEvents.data])

    useEffect(() => {
        const searchQuery = query.trim()
        const params = new URLSearchParams()
        if (searchQuery) {
            params.append("search", searchQuery)
        } else {
            params.delete("search")
        }
        history.push({search: params.toString()})
    }, [query, history])

    useEffect(() => {
        if (!isMobile && !loading && data?.UserLearningRoutes?.LearningRoute) {
            let menuWidth = [].slice.call(document.getElementsByClassName('MenuItemElement')).reduce((acc, lr: any) => {
                /* console.log({
                    word: lr.innerHTML,
                    length: lr.innerHTML.length + 0,
                    width: lr.offsetWidth,
                    letterWidth: lr.offsetWidth / lr.innerHTML.length
                }) */
                return acc + lr.offsetWidth
            }, 0)
            if (menuWidth > window.innerWidth) {
                // ¿Cuantos caben?
                // Promedio por letra: 12px.
            }
            /* for (const elementt of ) {
                menuWidth += elementt.offsetWidth
            } */
        }
    }, [isMobile, data, loading])

    const updateForm = (e: any, { name, value }: any) => {
        const update = {...form}
        // @ts-ignore
        update[name] = value
        setForm(update)
    }

    useEffect(() => {
        console.log(tokenCreationEvents.data)
        if (tokenCreationEvents?.data?.adminCreateToken?.success) {
            window.open(`/courses?supplant_token=${tokenCreationEvents.data.adminCreateToken.token}`, '_blank')?.focus()
        }
    }, [tokenCreationEvents.data])

    return <Grid className="ViewCourses">
        <TopBar
            centerColumn={<Input icon placeholder='Escribe aquí para buscar un usuario...' fluid className="SearchBar" value={form.search} name="search" onChange={updateForm}>
            <input onKeyPress={(event) => {
                if (event.key === 'Enter') {
                    setQuery(form.search)
                    setSearching(true)
                }
            }} />
            <Icon name='search' />
        </Input>} />
        <Confirm
            open={confirmDelete}
            content={`¿Estás seguro de eliminar la cuenta de usuario para ${selectedUser?.mail}?`}
            header={`Eliminación de cuenta de usuario para ${selectedUser?.lastName}, ${selectedUser?.firstName}`}
            onCancel={() => {
                setConfirmDelete(false)
                setSelectedUser(undefined)
            }}
            onConfirm={() => {
                setConfirmDelete(false)
                deleteEntity({
                    variables: {
                        AdminDeleteEntityInput: {
                            entity: 'User',
                            id: selectedUser?.id
                        }
                    }
                })
            }}
        />
        <Confirm
          open={confirm}
          content={`¿Estás seguro de reiniciar la contraseña para ${selectedUser?.mail}?`}
          header={`Reinicio de contraseña para ${selectedUser?.lastName}, ${selectedUser?.firstName}`}
          onCancel={() => {
            setConfirm(false)
            setSelectedUser(undefined)
          }}
          onConfirm={() => {
            setConfirm(false)
            doRecover({
                variables: {
                    UserRecoverPasswdInput: {
                        mail: selectedUser?.mail,
                        forceReset: true
                    }
                }
            })
          }}
        />
        <Grid.Row columns="equal">
            <AdminMenu />
            
            <Grid.Column>
                <Table basic='very' celled collapsing style={{ width: '100%' }}>
                    <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Participante</Table.HeaderCell>
                        <Table.HeaderCell>Grupos</Table.HeaderCell>
                        <Table.HeaderCell>Herramientas</Table.HeaderCell>
                    </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {data?.adminGetUserList?.users.map((user: UserDataType) => {
                            const hasRoot = user.UserGroups?.reduce((acc: boolean, ug: any) => {
                                if (!acc) { 
                                    return ug.Group.hasRoot
                                }
                                return acc
                            }, false)
                        return <Table.Row>
                            <Table.Cell>
                            <Header as='h4' image>
                                {/* <Label color='red' floating circular empty /> */}
                                <Image
                                    src='https://react.semantic-ui.com/images/avatar/small/matthew.png'
                                    size='mini'
                                    className='imageAvatar'
                                    rounded
                                    disabled={user.enabled === 0}
                                    label={hasRoot ? { as: 'a', color: 'blue', corner: 'left', icon: 'favorite' } : undefined}
                                    />
                                
                                <Header.Content>
                                {user.lastName}, {user.firstName}
                                <Header.Subheader>
                                    <Popup
                                        trigger={<Icon name='info circle' />}
                                        content={`Identificador único: ${user.id}`}
                                        inverted
                                        size='tiny'
                                        />{user.mail}
                                </Header.Subheader>
                                </Header.Content>
                            </Header>
                            </Table.Cell>
                            <Table.Cell>{user.UserGroups?.map((groups: any) => groups.Group.name).join(', ')}</Table.Cell>
                            <Table.Cell>
                                <Button.Group basic size='tiny'>
                                    <Button labelPosition='left' icon='key' content='Reestablecer contraseña' onClick={() => {
                                        setSelectedUser(user)
                                        setConfirm(true)
                                    }} />
                                    <Dropdown
                                        className='button icon'
                                        // trigger={<Button icon='ellipsis horizontal' />}
                                        floating>
                                        <Dropdown.Menu>
                                            <Dropdown.Header>Auditoría</Dropdown.Header>
                                            <Dropdown.Item icon='eye' text='Ingresar con esta identidad' onClick={() => {
                                                createToken({
                                                    variables: {
                                                        AdminUserLoginInput: {
                                                            mail: user.mail
                                                        }
                                                    }
                                                })
                                            }} />
                                            <Dropdown.Header>Configuración</Dropdown.Header>
                                            <Dropdown.Item icon='edit' text='Editar usuario' onClick={() => {
                                                history.push(`/admin/users/edit/${user.id}`)
                                            }} />
                                            <Dropdown.Item icon='delete' text='Eliminar usuario' onClick={() => {
                                                setSelectedUser(user)
                                                setConfirmDelete(true)
                                            }} />
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    
                                </Button.Group>
                            </Table.Cell>
                        </Table.Row>})}
                    </Table.Body>
                </Table>
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Pagination
                defaultActivePage={data?.adminGetUserList?.pagination.current}
                totalPages={data?.adminGetUserList?.pagination.last}
                ellipsisItem={data?.adminGetUserList?.pagination.last > 5 ? undefined : null}
                onPageChange={(event, pagedata) => {
                    // console.log(pagedata.activePage)
                    setLoading(true)
                    history.push({
                        pathname: '/admin/users/list',
                        search: `?page=${pagedata.activePage}&search=${search}`
                    })
                }}
                />
        </Grid.Row>
    </Grid>
}