import React, { useContext, useEffect } from 'react'

import {
    Grid,
    Icon,
    Header,
    Button,
    Form,
} from 'semantic-ui-react'

import './UserList.scss'

import { useSelector } from 'react-redux';
import LoaderContext from '../../Library/Context/LoaderContext'

import TopBar from '../../Components/TopBar'

import { AdminMenu } from './AdminMenu'
import { ADMIN_MAKE_REPORT } from './connections';
import { useMutation } from '@apollo/client';

export default function Report() {
    const { token } = useSelector((state: any) => state)
    
    const { setLoading } = useContext(LoaderContext)

    const [makeReport, { data, loading }] = useMutation(ADMIN_MAKE_REPORT, {
        fetchPolicy: 'no-cache',
        context: {
            headers: {
                authorization: `Bearer ${token}`
            }
        }
    })
    
    useEffect(() => {
        if (data) {
            setLoading(false)
            alert('El reporte será enviado al correo electrónico en unos segundos.')
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    useEffect(() => {
        setLoading(loading)
    }, [loading, setLoading])

    return <Grid className="ViewCourses">
        <TopBar />
        <Grid.Row columns="equal">
            <AdminMenu />
            
            <Grid.Column>
                <Grid.Row>
                    <Header as='h2'>
                        <Icon name='envelope' />
                        <Header.Content>
                        Solicitud de reporte
                        <Header.Subheader>Solicita el reporte a tu correo electrónico.</Header.Subheader>
                        </Header.Content>
                    </Header>
                </Grid.Row>
                <Grid.Row>
                    <Form style={{
                        marginTop: '12px'
                    }} onSubmit={(event, data) => {
                        makeReport()
                    }}>
                        <Button loading={loading} primary>Solicitar reporte</Button>
                    </Form>
                </Grid.Row>
            </Grid.Column>
        </Grid.Row>
    </Grid>
}